import React, { useState } from "react";
import { Button } from "react-bootstrap";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";
import { UpdatePassword } from "../../Services/Auth/Auth";
import { useNavigate } from "react-router-dom";

function UpdatePasswords() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleUpdate = async (event) => {
    setLoading(true);
    // Validate that both passwords are provided
    if (!currentPassword || !newPassword) {
      setSnackbarMessage("Both current and new passwords are required.");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setLoading(false);
      return; // Exit the function if validation fails
    }

    try {
      const response = await UpdatePassword({
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
      if (response.status === 200) {
        setSnackbarMessage("Password updated successfully!");
        setSnackbarOpen(true);
        setSnackbarSeverity("success");
        setLoading(false);
        setCurrentPassword("");
        setNewPassword("");
      } else if (response.status === 401) {
        setLoading(false);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        setSnackbarMessage("Failed to update password. Please try again.");
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setSnackbarMessage("An error occurred. Please try again later.");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      setLoading(false);
    }
  };

  return (
    <>
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleCloseSnackbar}
      />
      <div className="edit-profile-form">
        <div className="header my-8">
          <h5 className="fs-4 fw-bold">Update Password</h5>
        </div>
        <div className="w-100">
          {/* Current Password Field with Eye Toggle */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row gap-1 rounded-4 p-3 mb-3">
            <label className="fs-5 p-0 m-0">Current Password:</label>
            <div className="d-flex align-items-center flex-grow-1">
              <input
                type={isCurrentPasswordVisible ? "text" : "password"}
                className="flex-grow-1 p-0 m-0 fs-5"
                placeholder="Enter Current Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />

              <i
                className={`the-eye-button fa ${
                  isCurrentPasswordVisible ? "fa-eye" : "fa-eye-slash"
                }`}
                onClick={() =>
                  setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
                }
              ></i>
            </div>
          </div>

          {/* New Password Field with Eye Toggle */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between gap-1 rounded-4 p-3 w-100 flex-column flex-md-row">
            <label className="fs-5 p-0 m-0">New Password:</label>
            <div className="d-flex align-items-center flex-grow-1">
              <input
                type={isNewPasswordVisible ? "text" : "password"}
                className="flex-grow-1 p-0 m-0 fs-5"
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <i
                className={`the-eye-button fa ${
                  isNewPasswordVisible ? "fa-eye" : "fa-eye-slash"
                }`}
                onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
              ></i>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-3 my-4">
          <Button
            variant="primary"
            className="sale-now-button fs-5 rounded-2 py-3 px-5 my-4"
            onClick={handleUpdate}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default UpdatePasswords;
