import React, { useEffect, useState } from "react";
import "./SignUpWith.css";
import { Link, useNavigate } from "react-router-dom";
import { UseUser } from "../../Context/UserContext";
import { GetUser, loginWithGoogle } from "../../Services/Auth/Auth";
import Loader from "../../Components/Loader/Loader";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";

const NewAccount = () => {
  const { googleSignIn, setUser, facebookSignIn } = UseUser();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [location, setLocation] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            type: "Point",
            coordinates: [position.coords.latitude, position.coords.longitude],
          });
        },
        () => {
          setSnackbarMessage(
            "Unable to fetch location. Proceeding without it."
          );
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
        }
      );
    } else {
      setSnackbarMessage("Geolocation is not supported by your browser");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, []);
  const handleGoogleSignIn = async () => {
    try {
      const data = await googleSignIn();
      const userData = data.user;
      const name = userData.displayName;
      const email = userData.email;
      const googleId = userData.providerData[0].uid;

      const locationData = {
        email: email,
        name: name,
        googleId: googleId,
        location,
      };
      setLoading(true);
      const response = await loginWithGoogle(locationData);
      if (response.status === 200) {
        const sessionId = JSON.stringify(response.data.sessionId);
        localStorage.setItem("token", sessionId);
        localStorage.setItem("sessionStartTime", Date.now().toString());

        const res = await GetUser(response.data.sessionId);
        if (res.status === 200) {
          localStorage.setItem("user", JSON.stringify(res.data.data));
          setUser(res.data.data);
          setLoading(false);
          navigate("/", {
            state: { message: response.data.message },
          });
        }
      }

      // async () => {
      //   const proceedWithoutLocation = window.confirm(
      //     "Location is disabled. Would you like to log in without location?"
      //   );

      //   if (proceedWithoutLocation) {
      //     const locationData = {
      //       email: email,
      //       name: name,
      //       googleId: googleId,
      //     };
      //     setLoading(true);

      //     const response = await loginWithGoogle(locationData);
      //     if (response.status === 200) {
      //       const sessionId = JSON.stringify(response.data.sessionId);
      //       localStorage.setItem("token", sessionId);
      //       const sessionExpires = JSON.stringify(
      //         response.data.sessionExpires
      //       );
      //       localStorage.setItem("sessionExpires", sessionExpires);
      //       const res = await GetUser(response.data.sessionId);
      //       if (res.status === 200) {
      //         localStorage.setItem("user", JSON.stringify(res.data.data));
      //         setUser(res.data.data);
      //         setLoading(false);
      //         navigate("/", {
      //           state: { message: response.data.message },
      //         });
      //       }
      //     }
      //   }
      // }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        setSnackbarMessage(
          "Google Sign-In failed. Please try again later or use email/password login."
        );
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
      }
      setLoading(false);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      const data = await facebookSignIn();
      const userData = data.user;
      const name = userData.displayName;
      const email = userData.email;
      const googleId = userData.providerData[0].uid;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const locationData = {
              email: email,
              name: name,
              googleId: googleId,
              location: {
                type: "Point",
                coordinates: [
                  position.coords.latitude,
                  position.coords.longitude,
                ],
              },
            };
            setLoading(true);

            const response = await loginWithGoogle(locationData);
            if (response.status === 200) {
              setTimeout(async () => {
                const sessionId = JSON.stringify(response.data.sessionId);
                localStorage.setItem("token", sessionId);
                localStorage.setItem("sessionStartTime", Date.now().toString());

                const res = await GetUser(response.data.sessionId);
                if (res.status === 200) {
                  localStorage.setItem("user", JSON.stringify(res.data.data));
                  setUser(res.data.data);
                  setLoading(false);
                  navigate("/", {
                    state: { message: response.data.message },
                  });
                }
              }, 500);
            }
          },
          async () => {
            const proceedWithoutLocation = window.confirm(
              "Location is disabled. Would you like to log in without location?"
            );

            if (proceedWithoutLocation) {
              const locationData = {
                email: email,
                name: name,
                googleId: googleId,
              };
              setLoading(true);

              const response = await loginWithGoogle(locationData);
              if (response.status === 200) {
                setTimeout(async () => {
                  const sessionId = JSON.stringify(response.data.sessionId);
                  localStorage.setItem("token", sessionId);
                  localStorage.setItem(
                    "sessionStartTime",
                    Date.now().toString()
                  );

                  const res = await GetUser(response.data.sessionId);
                  if (res.status === 200) {
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    setUser(res.data.data);
                    setLoading(false);

                    navigate("/", {
                      state: { message: response.data.message },
                    });
                  }
                }, 2000);
              }
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="sign-up-bg d-flex align-items-center justify-content-center">
          <PositionedSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            handleClose={handleCloseSnackbar}
          />
          <div className="p-4 mx-2 sign-up-container">
            <div className="pt-3 mx-0 sign-up-text">
              <p className="p-0 m-0 text-center welcome-back">Welcome Back!</p>
              <p className="p-0 m-0 mb-3 text-center text">
                Please login to get access all features.
              </p>
            </div>
            <div className="create-account-with">
              {/* <div className="p-3 my-2 create-account-with-facebook rounded-4">
                <i
                  className="fa-brands fa-facebook fa-2xl d-inline pe-1"
                  style={{ color: "#1877f2" }}
                ></i>
                <button
                  className="fw-bold d-inline"
                  onClick={handleFacebookSignIn}
                  disabled={isLoading}
                >
                  Continue with Facebook
                </button>
              </div> */}
              <div className="p-3 my-2 create-account-with-facebook rounded-4">
                <img
                  src={require("../../Assets/search.png")}
                  className="d-inline ps-1 pe-1"
                  alt=""
                />
                <button
                  className="fw-bold d-inline"
                  onClick={handleGoogleSignIn}
                  disabled={isLoading}
                >
                  Continue with Google
                </button>
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div className="flex-row or d-flex align-items-center justify-content-center w-100">
                <hr className="w-25" />
                <div className="mx-3 d-inline fw-light">OR</div>
                <hr className="w-25" />
              </div>
              <div className="pb-1 create-account-button">
                <Link
                  className="py-2 my-2 border-0 btn btn-primary w-100 fw-bold fs-5 rounded-3"
                  to="/create-new-account"
                  role="button"
                >
                  <p className="p-0 m-0">Create New Account</p>
                </Link>
              </div>
              <div
                className="d-flex justify-content-center last"
                style={{ fontSize: "14px" }}
              >
                <span className="m-0 my-2 d-inline">
                  Already have an account? &nbsp;
                </span>
                <Link to="/login">
                  <span className="my-2 italic forgot-password d-flex justify-content-center">
                    Login
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NewAccount;
