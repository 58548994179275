import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./BookDetails.css";
import Rating from "@mui/material/Rating";
import Button from "react-bootstrap/Button";
import { GetOneBook } from "../../Services/Book/Book";
import { fetchCityName } from "../../libs/formateLocation";
import { calculateAverageRating } from "../../libs/calculateAvgerageRating";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import ShowMoreText from "react-show-more-text";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { imagePlaceHolder } from "../../libs/PlaceHolderImage";
import Loader from "../../Components/Loader/Loader";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { TextField } from "@mui/material";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";
import { CreateBookReview } from "../../Services/BookReview/BookReview";
import { UseUser } from "../../Context/UserContext";
import { HeartIcon as OutlineHeartIcon } from "@heroicons/react/24/outline";
import { HeartIcon as SolidHeartIcon } from "@heroicons/react/24/solid";
import { GetUser, Updatefavourite } from "../../Services/Auth/Auth";

const BookDetails = () => {
  const location = useLocation();
  const { user, setUser } = UseUser();
  const [isFavourite, setIsFavourite] = useState(false);
  const { bookId, backgroundColor } = location.state || {};
  const [book, setBook] = useState(null);
  const [city, setCity] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();
  const [rating, setRating] = useState({
    book_id: bookId,
    rating: 0.5,
    description: "",
  });

  useEffect(() => {
    if (user) {
      const Favourite = user.favouriteBooks.some(
        (favBook) => favBook._id === bookId
      );
      setIsFavourite(Favourite);
    }
  }, [bookId, user]);
  const handleHeartToggle = async () => {
    if (user) {
      const res = await Updatefavourite({
        book_id: bookId,
      });
      if (res.status === 200) {
        setSnackbarMessage(res.data.message);
        setSnackbarOpen(true);
        const token = localStorage.getItem("token");
        const parseToken = JSON.parse(token);
        const response = await GetUser(parseToken);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          setUser(response.data.data);
        } else if (response.status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          navigate("/login");
        }
        // setIsHeartFilled((prev) => !prev);
      } else if (res.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        setSnackbarMessage("Error occurred during favorite");
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
      }
    } else {
      navigate("/login");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const handleRatingChange = (event, newValue) => {
    setRating((prev) => ({
      ...prev,
      rating: newValue,
    }));
  };
  useEffect(() => {
    function fetchCity() {
      if (bookId) {
        GetOneBook(bookId)
          .then((data) => {
            if (data.status === 200) {
              setBook(data.data.data[0]);
              fetchCityName(data.data.data[0].location.coordinates, setCity);
            }
          })
          .catch((error) => {
            console.error("Error fetching book data:", error);
          });
      }
    }
    fetchCity();
  }, [bookId]);

  if (!book) {
    return <Loader />;
  }

  const averageRating = calculateAverageRating(book);
  const timeAgo = moment(book.createdAt).fromNow();
  const formattedDate = book.user
    ? moment(book.user.createdAt).format("MMMM YYYY")
    : "";
  function ratingDtae(createdAt) {
    return moment(createdAt).format("MMMM YYYY");
  }
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  const handleRatingClick = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleInputChange = (event) => {
    setRating((prev) => ({
      ...prev,
      description: event.target.value,
    }));
  };

  const handleButtonClick = async () => {
    if (rating.description.trim() === "") {
      setSnackbarMessage("Please fill review field");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
      return;
    }
    if (user) {
      const data = {
        book_id: rating.book_id,
        rating: rating.rating,
        description: rating.description,
      };

      const response = await CreateBookReview(data);

      if (response.status === 201) {
        const newReview = {
          userName: user.name, // Assuming the response includes the reviewer's name
          rating: rating.rating,
          description: rating.description,
          createdAt: new Date().toISOString(), // Using the current date for the review date
          profileImage: user?.profileImage, // Assuming the response includes the profile image
        };

        // Add the new review to the book's reviews
        setBook((prevBook) => ({
          ...prevBook,
          reviews: [newReview, ...prevBook.reviews], // Add the new review at the beginning of the array
        }));

        // Show success message
        setSnackbarMessage("Review submitted successfully!");
        setSnackbarOpen(true);
        setSnackbarSeverity("success");

        // Close the modal after submission
        setIsModalOpen(false);

        // Reset the rating state
        setRating({
          book_id: bookId,
          rating: 0,
          description: "",
        });
      } else if (response.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // Show error message if the response is not successful
        setSnackbarMessage("Failed to submit review. Please try again.");
        setSnackbarOpen(true);
        setSnackbarSeverity("error");
      }
    } else {
      navigate("/login");
    }
  };
  function formatPhoneNumber(number) {
    // Check if the number starts with a '+' (indicating it already has a country code)
    if (!number.startsWith("+")) {
      // Add the default country code if it's missing
      const defaultCountryCode = "+92"; // Change this if needed
      number = defaultCountryCode + number.replace(/^0+/, ""); // Remove any leading zeroes
    }
    return number;
  }
console.log(book);

return (
  <div className="book-details-main-container">
    <div className="book-details-container d-flex align-items-center justify-content-evenly flex-column flex-md-row row-gap-4">
      <Swiper
        className="book-details-image d-flex align-items-center justify-content-center rounded-4 mySwiper"
        style={{
          backgroundColor: backgroundColor || "#ffffff",
          height: "300px",
        }}
        navigation={true}
        modules={[Navigation]}
        freeMode={true}
      >
        {book.images.length > 0 ? (
          book.images.map((item, index) => (
            <SwiperSlide
              className="d-flex align-items-center justify-content-center"
              key={index}
            >
              <div
                className="d-inline-block"
                style={{ width: "100%", height: "100%" }}
              >
                <img src={item} alt={item} className="rounded-4" />
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide className="d-flex align-items-center justify-content-center">
            <div className="d-inline-block px-5 py-3">
              <img
                src={imagePlaceHolder}
                alt={imagePlaceHolder}
                className="rounded-4"
              />
            </div>
          </SwiperSlide>
        )}
      </Swiper>
      <div className="book-details-text text-start w-100 d-flex flex-column gap-2">
        <div className=" d-flex align-items-center justify-content-between">
          <div className="m-0">
            <h3 className="book-details-title m-0 fw-bold fs-3 d-block d-md-inline mobile-text-larger">
              {book.title}
            </h3>
            <p
              className="m-0 fw-light d-inline underline"
              style={{ fontSize: "14px" }}
            >
              by {book.author}
            </p>
          </div>

          <button
            onClick={handleHeartToggle}
            style={{ background: "none", border: "none", cursor: "pointer" }}
          >
            {isFavourite ? (
              <SolidHeartIcon className="h-6 w-6 text-red-500" />
            ) : (
              <OutlineHeartIcon className="h-6 w-6 text-gray-500" />
            )}
          </button>
        </div>
        <div className="location-row d-flex align-items-center justify-content-between">
          <p className="book-details-price m-0 py-1 px-3 fs-6 fw-medium rounded-5 mobile-text-medium">
            Rs.{book.price}
          </p>
          <div
            className="d-flex align-items-center"
            onClick={handleRatingClick}
            style={{ cursor: "pointer" }}
          >
            <Rating
              size="small"
              name="half-rating-read"
              value={averageRating}
              precision={0.5}
              readOnly
            />
            <p
              className="m-0 ms-1 mobile-text-medium"
              style={{ fontSize: "12px", color: "#faaf00" }}
            >
              {averageRating}/5
            </p>
          </div>
        </div>
        <p className="m-0 fw-medium mobile-text-medium">
          Category:{" "}
          <span className="fw-normal text-muted ms-1">
            {book.category?.title}
          </span>
        </p>
        <p className="m-0 fw-medium mobile-text-medium">
          Language:{" "}
          <span className="fw-normal text-muted ms-1">{book.language}</span>
        </p>
        {book.ISBN !== "" && (
          <p className="m-0 fw-medium mobile-text-medium">
            ISBN: <span className="fw-normal text-muted ms-1">{book.ISBN}</span>
          </p>
        )}
        {book.condition !== "" && (
          <p className="m-0 fw-medium mobile-text-medium">
            Condition:{" "}
            <span className="fw-normal text-muted ms-1">{book.condition}</span>
          </p>
        )}
        {book.shippingDetail !== "" && (
          <p className="m-0 fw-medium mobile-text-medium">
            Shipping Detail:{" "}
            <span className="fw-normal text-muted ms-1">
              {book.shippingDetail}
            </span>
          </p>
        )}
        {book.pages && (
          <p className="m-0 fw-medium mobile-text-medium">
            Pages:{" "}
            <span className="fw-normal text-muted ms-1">{book.pages}</span>
          </p>
        )}
        {book.stockQuantity && (
          <p className="m-0 fw-medium mobile-text-medium">
            {book.isOnDemand ? "Required Quantity: " : "Stock Quantity: "}
            <span className="fw-normal text-muted ms-1">
              {book.stockQuantity}
            </span>
          </p>
        )}
        {book.saleStatus !== "" && (
          <p className="m-0 fw-medium mobile-text-medium">
            Sell Status:{" "}
            <span className="fw-normal text-muted ms-1">{book.saleStatus}</span>
          </p>
        )}
        {book.publisher !== "" && (
          <p className="m-0 fw-medium mobile-text-medium">
            Publisher:{" "}
            <span className="fw-normal text-muted ms-1">{book.publisher}</span>
          </p>
        )}

        {book.isExchangeable && (
          <div
            class="  d-flex justify-content-end"
            style={{ color: "rgba(0, 154, 240, 1)" }}
          >
            Exchangeable
          </div>
        )}
        <div className="location-row d-flex align-items-center justify-content-between">
          {city !== "Location not found" && (
            <p className="book-details-location m-0 fs-6 mobile-text-medium">
              <i
                className="fa-solid fa-location-dot me-2 mobile-text-medium"
                style={{ color: "#000000" }}
              />
              {city}
            </p>
          )}
          <p className="book-details-time m-0 align-self-md-end mobile-text-medium">
            {timeAgo}
          </p>
        </div>
        <div className="book-details-seller-details d-flex align-items-center column-gap-3 justify-content-start">
          {book.user && book.user.profileImage !== "" ? (
            <img
              src={book.user.profileImage}
              alt={book.user.name}
              className="seller-image rounded-circle"
              style={{ width: "50px", height: "50px", objectFit: "cover" }}
            />
          ) : (
            <div
              className="profile-image-before d-flex align-items-center justify-content-center"
              style={{ width: "50px", height: "50px" }}
            >
              <i
                className="fa-solid fa-user"
                style={{ color: "white", fontSize: "20px" }}
              ></i>
            </div>
          )}

          <div className="seller-info">
            <p className="seller-name m-0 fw-bold fs-5  mobile-text-large">
              {book.user && book.user.name}
            </p>
            <p className="seller-member-since m-0 fw-medium mobile-text-small">
              Member since:{" "}
              <p className="m-0 fw-normal d-inline mobile-text-small">
                {" "}
                <i>{formattedDate}</i>
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>

    {book.description !== "" && (
      <div className="book-description-section fw-medium my-5">
        <h4 className="mb-2 mobile-text-larger">Description</h4>
        <p className="m-0 fw-light mobile-text-medium">{book.description}</p>
      </div>
    )}
    {book.phoneNuber && (
      <div className="d-flex align-items-center justify-content-center my-5">
        <a
          href={`https://wa.me/${formatPhoneNumber(book.phoneNuber)}`}
          target="_blank"
          // variant="primary"
          className="sale-now-button fs-6 rounded-4 w-100 py-3 d-flex align-items-center justify-content-center text-white"
          style={{ textDecoration: "none" }}
          rel="noreferrer"
        >
          <i className="fa-regular fa-comment-dots me-2" />
          Chat
        </a>
      </div>
    )}
    <CustomModal isOpen={isModalOpen} onClose={handleCloseModal}>
      <h4 className="ms-2 mt-2">Give Review</h4>
      <div className="ms-2 mt-2 mb-4 custom-review-box">
        <Rating
          size="large"
          name="half-rating-read"
          value={rating.rating}
          precision={0.5}
          onChange={handleRatingChange}
        />
        <div className="review-field">
          <TextField
            label="Enter your Review"
            variant="outlined"
            value={rating.description}
            onChange={handleInputChange}
            sx={{
              width: {
                xs: "100%", // Full width on extra small and small screens
                sm: "100%", // Full width on small screens
                md: "300px", // Specific width on medium and larger screens
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(0, 154, 240, 1)", // Set the border color to blue
                },
                "&:hover fieldset": {
                  borderColor: "rgba(0, 154, 240, 1)", // Blue border on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(0, 154, 240, 1)", // Blue border when focused
                },
              },
            }}
          />
          <Button
            variant="primary"
            className="sale-now-button fs-6 rounded-4 py-3  custom-button"
            onClick={handleButtonClick}
          >
            Submit
          </Button>
        </div>
      </div>

      {book.reviews.filter(
        (review) => review && review.userName && review.description
      ).length > 0 && <h4 className="ms-2 mt-2 fs-6">Book Reviews</h4>}
      <div
        className="review-section p-0 max-w-50 scroll"
        style={{
          overflowY: "auto",
          height:
            book.reviews.filter(
              (review) => review && review.userName && review.description
            ).length > 0 && "12rem",
        }}
      >
        {book.reviews
          .filter((review) => review && review.userName && review.description)
          .map((review, index) => (
            <div key={index} className="book-review-item p-2">
              <div className="d-flex align-items-center column-gap-3 justify-content-start">
                {review.profileImage !== "" ? (
                  <img
                    src={review.profileImage}
                    alt={review.userName}
                    className="review-image rounded-circle align-self-start"
                    style={{ width: "45px", height: "45px" }}
                  />
                ) : (
                  <div
                    className="profile-image-before d-flex align-items-center justify-content-center"
                    style={{ width: "45px", height: "45px" }}
                  >
                    <i
                      className="fa-solid fa-user"
                      style={{ color: "white", fontSize: "15px" }}
                    ></i>
                  </div>
                )}
                <div className="">
                  <p className="m-0 fw-bold fs-6 mobile-text-small">
                    {review.userName}
                  </p>
                  <p className="m-0 mobile-text-small">
                    Review Date: <i>{ratingDtae(review.createdAt)}</i>
                  </p>
                </div>
              </div>
              <div className="review-paragraph" style={{ paddingLeft: "60px" }}>
                <Rating
                  className="mt-2"
                  size="small"
                  name={`rating-${index}`}
                  value={review.rating}
                  precision={0.1}
                  readOnly
                />
                <ShowMoreText
                  lines={2}
                  more="Show more"
                  less="...Show less"
                  className="content-css mobile-text-small"
                  anchorClass="show-more-less-clickable"
                  expanded={false}
                  truncatedEndingComponent={" ..."}
                >
                  <p className="p-0">{review.description}</p>
                </ShowMoreText>
              </div>
            </div>
          ))}
      </div>
    </CustomModal>
    <PositionedSnackbar
      open={snackbarOpen}
      message={snackbarMessage}
      severity={snackbarSeverity}
      handleClose={handleCloseSnackbar}
    />
  </div>
);
};

export default BookDetails;
