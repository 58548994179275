// BookButtons.js
import React from 'react';
import Button from 'react-bootstrap/Button';
import './DemandingBookButton.css'; // Optional: Add specific styles for the buttons
import { useNavigate } from "react-router-dom";

const DemandingBookButton = ({
  bookId,
  backgroundColor,
  buttonText = "Sell Now",
}) => {
  const navigate = useNavigate();
  const handleViewDetails = () => {
    navigate("/book-details", {
      state: { bookId, backgroundColor, buttonText },
    });
  };

  return (
    <div className="third-row d-flex justify-content-between align-items-center">
      <Button
        onClick={handleViewDetails}
        variant="outline-primary"
        className="view-book-button mt-2 w-100 fs-md-5 fs-6"
      >
        Sell Now
      </Button>
    </div>
  );
};

export default DemandingBookButton;
