import React, { createContext, useState, useEffect, useContext } from "react";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../Config/Firebase";
export const UserContext = createContext();
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const userFromStorage = JSON.parse(localStorage.getItem("user"));
  const sessionExpires = JSON.parse(localStorage.getItem("sessionExpires"));
  useEffect(() => {
    function checkSession() {
      if (userFromStorage && sessionExpires) {
        const currentTime = new Date();
        const expirationTime = new Date(sessionExpires);
        if (currentTime >= expirationTime) {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("sessionExpires");
          setUser(null);
        } else {
          setUser(userFromStorage);
        }
      }
    }
    checkSession();
  }, []);

  const googleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    return result;
  };

  const facebookSignIn = async () => {
    const provider = new FacebookAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      return result;
    } catch (error) {
      console.error("Error during Facebook sign-in: ", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        googleSignIn,
        facebookSignIn,
        setSelectedCategory,
        selectedCategory,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function UseUser() {
  return useContext(UserContext);
}
