import React, { useEffect, useState } from "react";
import "../SellBookForm/SellBookForm.css";
import { Dropdown } from "primereact/dropdown";
import Button from "react-bootstrap/Button";
import AddImages from "../../../Components/AddImages/AddImages";
import CustomModal from "../../../Components/CustomModal/CustomModal"; // Import the modal component
import { GetCategory } from "../../../Services/Category/Category";
import PositionedSnackbar from "../../../Components/Snackbar/Snackbar";

import Modal from "react-bootstrap/Modal";
import Map from "../../../Components/Map";
import { GetOneBook, UpdateBook } from "../../../Services/Book/Book";
import { useNavigate, useParams } from "react-router-dom";
const UpdateBookForm = () => {
  const { id } = useParams();

  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [isExchangeable, setIsExchangeable] = useState(false);

  // New state variables for additional fields
  const [bookTitle, setBookTitle] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookISBN, setBookISBN] = useState("");
  const [bookEdition, setBookEdition] = useState("");
  const [bookPublisher, setBookPublisher] = useState("");
  const [bookPages, setBookPages] = useState("");
  const [bookFormat, setBookFormat] = useState("");
  const [stockQuantity, setStockQuantity] = useState("");
  const [discount, setDiscount] = useState("");
  const [saleStatus, setSaleStatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shippingDetail, setShippingDetail] = useState("");
  const [tags, setTags] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState(""); // State for book description
  const [inputValue, setInputValue] = useState(""); // State for current input value
  const [showOptionalFields, setShowOptionalFields] = useState(false);

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const navigate = useNavigate();
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [locationCoordinates, setLocationCoordinates] = useState(null);
  const [location, setLocation] = useState("");
  const [initialBookData, setInitialBookData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      GetOneBook(id)
        .then((data) => {
          if (data.status === 200) {
            const res = data.data.data[0];
            let upperCaseCondition;
            if (res.condition === "New") {
              upperCaseCondition = res.condition.toUpperCase();
            } else {
              upperCaseCondition = res.condition
                .replace(/^Used - /i, "")
                .toUpperCase();
            }
            const matchedLanguage =
              languages.find((lang) => lang.name === res.language) || null;

            const resCategory = {
              createdAt: res.category.createdAt,
              icon: res.category.icon,
              isActive: res.category.isActive,
              title: res.category.title,
              updatedAt: res.category.updatedAt,
              _id: res.category._id,
            };
            setBookTitle(res.title || "");
            setBookAuthor(res.author || "");
            setBookISBN(res.ISBN || "");
            setBookEdition(res.edition || "");
            setBookPublisher(res.publisher || "");
            setBookPages(res.pages || "");
            setBookFormat(res.formate || "");
            setStockQuantity(res.stockQuantity || "");
            setDiscount(res.discount || "");
            setSaleStatus(res.saleStatus || "");
            setPhoneNumber(res.phoneNuber || ""); // Typo corrected: phoneNumber
            setShippingDetail(res.shippingDetail || "");
            setTags(res.tags || []);
            setPrice(res.price || "");
            setDescription(res.description || "");
            setSelectedCondition(upperCaseCondition || ""); // Set selected condition
            setSelectedLanguage(matchedLanguage || ""); // Set selected language
            setSelectedCategory(resCategory || null); // Set selected category
            setLocation("");
            setLocationCoordinates(res.location.coordinates || null);
            setIsExchangeable(res.isExchangeable);
            setInitialBookData({
              title: res.title || "",
              author: res.author || "",
              ISBN: res.ISBN || "",
              edition: res.edition || "",
              publisher: res.publisher || "",
              pages: res.pages || "",
              formate: res.formate || "",
              stockQuantity: res.stockQuantity || "",
              discount: res.discount || "",
              saleStatus: res.saleStatus || "",
              phoneNuber: res.phoneNuber || "",
              shippingDetail: res.shippingDetail || "",
              tags: res.tags || [],
              price: res.price || "",
              description: res.description || "",
              condition: upperCaseCondition || "",
              language: res.language || "",
              category: res.category || null,
              locationCoordinates: res.location.coordinates || null,
              isOnDemand: res.isOnDemand,
              isExchangeable: res.isExchangeable,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching book data:", error);
        });
    }
  }, [id]);

  useEffect(() => {
    async function GetCategories() {
      const fetchCategory = await GetCategory();
      if (fetchCategory.status === 200) {
        const categories = fetchCategory.data.data;
        setCategory(categories);
      }
    }
    GetCategories();
  }, []);

  const handleConditionClick = (condition) => {
    setSelectedCondition(condition === selectedCondition ? null : condition);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const languages = [
    { code: "ur", name: "Urdu" },
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "pa", name: "Punjabi" },
    { code: "sd", name: "Sindhi" },
    { code: "ps", name: "Pashto" },
    { code: "gk", name: "Gilgiti" },
  ];

  const handleLocationModalOpen = () => {
    setLocationModalOpen(true);
  };

  const handleLocationModalClose = () => {
    setLocationModalOpen(false);
  };

  // Fetch coordinates using Google Maps Geocoding API
  const fetchCoordinates = async (locationSearch) => {
    setLocation(locationSearch);
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${locationSearch}&key=AIzaSyCRCOwFuGi-fB19Qio-Mhy2qN7NTZapG18`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setLocationCoordinates(location);
      } else {
        alert("Location not found. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching location data: ", error);
    }
  };

  const conditionMap = {
    NEW: "New",
    GOOD: "Used - Good",
    ACCEPTABLE: "Used - Acceptable",
    "LIKE NEW": "Used - Like New",
  };
  const conditions = Object.keys(conditionMap);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSale = async () => {
    try {
      setLoading(true);
      const formdata = new FormData();
      const formattedCondition = conditionMap[selectedCondition];
      if (selectedCategory) {
        if (selectedCategory._id !== initialBookData.category?._id) {
          formdata.append("cat_id", selectedCategory._id);
        }
      }
      if (bookTitle !== initialBookData.title) {
        formdata.append("title", bookTitle);
      }
      if (price !== String(initialBookData.price)) {
        formdata.append("price", String(price));
      }

      if (formattedCondition !== initialBookData.condition) {
        formdata.append("condition", formattedCondition);
      }
      if (bookAuthor !== initialBookData.author) {
        formdata.append("author", bookAuthor);
      }
      if (selectedLanguage?.name !== initialBookData.language?.name) {
        formdata.append("language", selectedLanguage.name);
      }
      if (bookISBN !== initialBookData.ISBN) {
        formdata.append("ISBN", bookISBN);
      }
      if (bookEdition !== initialBookData.edition) {
        formdata.append("edition", bookEdition);
      }
      if (bookPublisher !== initialBookData.publisher) {
        formdata.append("publisher", bookPublisher);
      }
      if (bookPages !== String(initialBookData.pages)) {
        formdata.append("pages", String(bookPages));
      }
      if (bookFormat !== initialBookData.formate) {
        formdata.append("formate", bookFormat);
      }
      if (stockQuantity !== String(initialBookData.stockQuantity)) {
        formdata.append("stockQuantity", String(stockQuantity));
      }
      if (discount !== String(initialBookData.discount)) {
        formdata.append("discount", String(discount));
      }
      if (saleStatus !== initialBookData.saleStatus) {
        formdata.append("saleStatus", saleStatus);
      }
      if (description !== initialBookData.description) {
        formdata.append("description", description);
      }
      if (phoneNumber !== initialBookData.phoneNuber) {
        formdata.append("phoneNuber", phoneNumber);
      }
      if (shippingDetail !== initialBookData.shippingDetail) {
        formdata.append("shippingDetail", shippingDetail);
      }
      if (
        tags?.length &&
        JSON.stringify(tags) !== JSON.stringify(initialBookData.tags)
      ) {
        formdata.append("tags", JSON.stringify(tags));
      }
      if (
        JSON.stringify(isExchangeable) !==
        JSON.stringify(initialBookData.isExchangeable)
      ) {
        formdata.append("isExchangeable", isExchangeable);
      }
      if (locationCoordinates !== initialBookData.locationCoordinates) {
        formdata.append(
          "location",
          JSON.stringify({
            type: "Point",
            coordinates: [locationCoordinates.lat, locationCoordinates.lng],
          })
        );
      }

      previews.forEach((file, index) => {
        if (file) formdata.append(`images`, file, file.name);
      });

      const res = await UpdateBook(id, formdata);

      if (res.status === 200) {
        setSnackbarMessage("Book updated successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setLoading(false);

        setTimeout(() => {
          navigate("/my-book-ads");
        }, 1000);
      } else if (res.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        setLoading(false);

        navigate("/login");
      }
    } catch (error) {
      console.error("Error updating BOOK:", error);
      setLoading(false);

      setSnackbarMessage("Failed to update BOOK.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update input value
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault(); // Prevent form submission

      setTags((prevTags) => [...prevTags, inputValue.trim()]); // Add tag to the array
      setInputValue(""); // Clear the input
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove)); // Remove tag from the array
  };
  const handleCheckboxChange = (e) => {
    setIsExchangeable(e.target.checked);
  };
  return (
    <div className="sell-book-form">
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
      <div className="header">
        <h5 className="fs-4 fw-bold">Sale Book</h5>
      </div>
      <AddImages previews={previews} setPreviews={setPreviews} />

      {/* Title Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label htmlFor="bookTitle" className="fs-5 p-0 m-0 w-max">
          Title:
        </label>
        <input
          type="text"
          id="bookTitle"
          value={bookTitle}
          onChange={(e) => setBookTitle(e.target.value)}
          placeholder="Enter book title"
          className="flex-grow p-0 m-0 fs-5 w-100"
        />
      </div>
      {/* Phone Number Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label htmlFor="phoneNumber" className="fs-5 p-0 m-0 w-max">
          Phone Number:
        </label>
        <input
          type="text"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Enter phone number"
          className="flex-grow p-0 m-0 fs-5 w-100"
        />
      </div>

      {/* Author Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label htmlFor="bookAuthor" className="fs-5 p-0 m-0 w-max">
          Author:
        </label>
        <input
          type="text"
          id="bookAuthor"
          value={bookAuthor}
          onChange={(e) => setBookAuthor(e.target.value)}
          placeholder="Enter author name"
          className="flex-grow p-0 m-0 fs-5 w-100"
        />
      </div>
      {/* Publisher Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label htmlFor="bookPublisher" className="fs-5 p-0 m-0 w-max">
          Publisher:
        </label>
        <input
          type="text"
          id="bookPublisher"
          value={bookPublisher}
          onChange={(e) => setBookPublisher(e.target.value)}
          placeholder="Enter publisher"
          className="flex-grow p-0 m-0 fs-5 w-100"
        />
      </div>
      {/* Condition Selection */}
      <div className="field-container rounded-4 py-2 px-3 m-0 my-3 my-lg-0">
        <div className="d-flex flex-wrap gap-1">
          <label className="fs-5 p-0 m-0 flex-grow-0 d-flex align-items-center w-max">
            Condition:
          </label>
          {conditions.map((condition) => (
            <button
              key={condition}
              className={`condition-button text-center rounded-5 py-md-2 px-md-4 px-3 py-2 flex-grow-0 ${
                condition === selectedCondition ? "selected-book-condition" : ""
              }`}
              onClick={() => handleConditionClick(condition)}
            >
              {condition}
            </button>
          ))}
        </div>
      </div>

      {/* Price Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label htmlFor="bookPrice" className="fs-5 p-0 m-0 w-max">
          Price:
        </label>
        <input
          type="number"
          id="bookPrice"
          placeholder="Enter price"
          className="flex-grow p-0 m-0 fs-5 w-100"
          value={price} // Bind state value here
          onChange={(e) => setPrice(e.target.value)} // Update state on change
        />
      </div>

      {/* Discount Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label htmlFor="discount" className="fs-5 p-0 m-0 w-max">
          Discount:
        </label>
        <input
          type="number"
          id="discount"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          placeholder="Enter discount percentage"
          className="flex-grow p-0 m-0 fs-5 w-100"
        />
      </div>

      <div class="checkbox-container">
        <input
          type="checkbox"
          id="checkbox"
          class="checkbox"
          checked={isExchangeable}
          onChange={handleCheckboxChange}
        />
        <label for="checkbox" class="checkbox-label-exchange">
          Exchangeable
        </label>
      </div>
      {/* Description Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label
          htmlFor="bookDescription"
          className="fs-5 p-0 m-0 align-self-start w-max"
        >
          Description:
        </label>
        <textarea
          rows={5}
          id="bookDescription"
          placeholder="Enter book description"
          className="flex-grow p-0 m-0 fs-5"
          value={description} // Bind state value here
          onChange={(e) => setDescription(e.target.value)} // Update state on change
        />
      </div>
      <div className="d-flex justify-content-center my-4">
        <Button
          variant="link"
          onClick={() => setShowOptionalFields(!showOptionalFields)}
          className="fs-6 fs-md-5 rounded-4 py-3 text-gray"
          style={{ color: "gray", textDecoration: "none" }}
        >
          {showOptionalFields
            ? "Hide Optional Fields"
            : "Show Book Details (Optional)"}
        </Button>
      </div>

      {showOptionalFields && (
        <>
          {/* Category Selection */}
          <div className="field-container align-items-center rounded-4 py-2 px-3 m-0 my-3 ">
            <div className="d-flex flex-wrap gap-4  align-items-center ">
              <label htmlFor="category" className="fs-5 p-0 m-0 w-max">
                Category:
              </label>
              <Dropdown
                id="category"
                value={selectedCategory} // This should hold the full category object
                options={category} // Assuming 'categories' is your array of category objects
                onChange={(e) => {
                  setSelectedCategory(e.value); // Store the selected category object
                }}
                placeholder="Select a category"
                className=""
                optionLabel="title" // Use 'title' to display in the dropdown
              />
            </div>
          </div>

          {/* Languages Selection */}

          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100">
            <label className="fs-5 p-0 m-0">Language:</label>
            <div className="w-100">
              <Dropdown
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.value)}
                options={languages}
                optionLabel="name"
                showClear
                placeholder="Select Language"
                className="custom-dropdown d-flex align-items-center justify-content-between fs-5"
              />
            </div>
          </div>

          {/* ISBN Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookISBN" className="fs-5 p-0 m-0 w-max">
              ISBN:
            </label>
            <input
              type="text"
              id="bookISBN"
              value={bookISBN}
              onChange={(e) => setBookISBN(e.target.value)}
              placeholder="Enter ISBN"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>

          {/* Edition Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookEdition" className="fs-5 p-0 m-0 w-max">
              Edition:
            </label>
            <input
              type="text"
              id="bookEdition"
              value={bookEdition}
              onChange={(e) => setBookEdition(e.target.value)}
              placeholder="Enter edition"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>

          {/* Pages Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookPages" className="fs-5 p-0 m-0 w-max">
              Pages:
            </label>
            <input
              type="number"
              id="bookPages"
              value={bookPages}
              onChange={(e) => setBookPages(e.target.value)}
              placeholder="Enter number of pages"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>

          {/* Format Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookFormat" className="fs-5 p-0 m-0 w-max">
              Format:
            </label>
            <input
              type="text"
              id="bookFormat"
              value={bookFormat}
              onChange={(e) => setBookFormat(e.target.value)}
              placeholder="Enter format (e.g., Paperback, Hardcover)"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>

          {/* Stock Quantity Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="stockQuantity" className="fs-5 p-0 m-0 w-max">
              {initialBookData.isOnDemand
                ? "Required Quantity:"
                : "Stock Quantity:"}
            </label>
            <input
              type="number"
              id="stockQuantity"
              value={stockQuantity}
              onChange={(e) => setStockQuantity(e.target.value)}
              placeholder="Enter stock quantity"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>

          {/* Sale Status Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="saleStatus" className="fs-5 p-0 m-0 w-max">
              Sale Status:
            </label>
            <input
              type="text"
              id="saleStatus"
              value={saleStatus}
              onChange={(e) => setSaleStatus(e.target.value)}
              placeholder="Enter sale status"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>

          {/* Shipping Detail Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="shippingDetail" className="fs-5 p-0 m-0 w-max">
              Shipping Detail:
            </label>
            <input
              type="text"
              id="shippingDetail"
              value={shippingDetail}
              onChange={(e) => setShippingDetail(e.target.value)}
              placeholder="Enter shipping detail"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>

          {/* Add a button to open the location modal */}
          {!locationCoordinates && (
            <Button
              style={{
                color: "#0084f0",
                borderColor: "#0084f0",
              }}
              variant="outline"
              onClick={handleLocationModalOpen}
            >
              Add Location
            </Button>
          )}
          {/* Location Modal */}
          <Modal show={locationModalOpen} onHide={handleLocationModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Select Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Map
                fetchCoordinates={fetchCoordinates}
                handleLocationModalClose={handleLocationModalClose}
              />
            </Modal.Body>
          </Modal>

          {/* Display selected location */}
          {location !== "" && (
            <div className="selected-location">
              <p>Selected Location: {location}</p>
            </div>
          )}

          {/* Tags Field */}
          <div className="field-container d-flex align-items-start align-items-md-center  flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="tags" className="fs-5 p-0 m-0 w-max">
              Tags:
            </label>
            <div className="d-flex flex-wrap flex-grow">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className="tag-container d-flex align-items-center me-2 mb-2 p-1 rounded-lg"
                  style={{ backgroundColor: "#c5c5c5c5" }}
                >
                  <span className="tag">{tag}</span>
                  <button
                    type="button"
                    className="btn-close ms-2"
                    onClick={() => removeTag(tag)}
                    aria-label="Remove tag"
                  ></button>
                </div>
              ))}
              <input
                type="text"
                id="tags"
                value={inputValue} // Bind input value
                onChange={handleInputChange} // Update input value on change
                onKeyPress={handleKeyPress} // Handle Enter key press
                placeholder="Enter tags and hit enter"
                className="flex-grow p-0 m-0 fs-5"
              />
            </div>
          </div>
        </>
      )}
      <Button
        className="bg-primary text-white w-full sm:w-auto"
        onClick={handleSale}
        disabled={loading}
      >
        {loading ? "Loading" : "Update Book"}
      </Button>

      {/* Custom Modal for confirmation */}
      <CustomModal open={modalOpen} onClose={handleModalClose}>
        <h5>Confirm your details:</h5>
        {/* Display the details here */}
        <div>
          <p>Title: {bookTitle}</p>
          <p>Author: {bookAuthor}</p>
          <p>ISBN: {bookISBN}</p>
          <p>Edition: {bookEdition}</p>
          <p>Publisher: {bookPublisher}</p>
          <p>Pages: {bookPages}</p>
          <p>Format: {bookFormat}</p>
          <p>Stock Quantity: {stockQuantity}</p>
          <p>Discount: {discount}</p>
          <p>Sale Status: {saleStatus}</p>
          <p>Phone Number: {phoneNumber}</p>
          <p>Shipping Detail: {shippingDetail}</p>
          <p>Tags: {tags}</p>
        </div>
        <Button
          className="bg-success text-white"
          onClick={() => {
            handleModalClose();
          }}
        >
          Confirm
        </Button>
      </CustomModal>
    </div>
  );
};

export default UpdateBookForm;
