export const formatDate = (isoString) => {
  const date = new Date(isoString);

  // Extract hours and minutes in UTC
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Format hours and minutes in 12-hour format
  const formattedTime = `${hours % 12 || 12}:${minutes
    .toString()
    .padStart(2, "0")} ${hours >= 12 ? "PM" : "AM"}`;

  return formattedTime;
};
