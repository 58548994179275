import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Switch } from "@mui/material"; // Import the Switch component from Material-UI
import "./MyBookAdCard.css";
import { fetchCityName } from "../../libs/formateLocation";
import { imagePlaceHolder } from "../../libs/PlaceHolderImage";
import { DeleteBook, UpdateBook } from "../../Services/Book/Book";
import { useNavigate } from "react-router-dom";

const MyBookAdCard = ({
  book,
  backgroundColor,
  setBooks,
  setVisibleBooks,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  const navigate = useNavigate();
  const [city, setCity] = useState("");

  useEffect(() => {
    function fetchCity() {
      if (book) {
        fetchCityName(book.location.coordinates, setCity);
      }
    }
    fetchCity();
  });

  const handleEdit = (bookId) => {
    navigate(`/sell-book-form/${bookId}`);
  };

  const handleRemove = async (bookId) => {
    try {
      const response = await DeleteBook(bookId);

      if (response.status === 200) {
        setSnackbarOpen(true);
        setSnackbarMessage("The book has been deleted successfully!");
        setSnackbarSeverity("success");

        // Update the book state locally if the API call succeeds
        setBooks((prevBooks) => prevBooks.filter((b) => b._id !== bookId));
        setVisibleBooks((prevBooks) =>
          prevBooks.filter((b) => b._id !== bookId)
        );
      } else {
        throw new Error("Failed to delete the book.");
      }
    } catch (error) {
      console.error("Error deleting the book:", error);
      setSnackbarOpen(true);
      setSnackbarMessage("Error deleting the book. Please try again.");
      setSnackbarSeverity("error");
    }
  };

  const handleToggle = async (bookId) => {
    try {
      // Call your API to update the isPublish status based on current state
      const response = await UpdateBook(bookId, { isPublish: !book.isPublish });

      if (response.status === 200) {
        setSnackbarOpen(true);
        setSnackbarMessage(
          book.isPublish
            ? "The book is now unpublished!"
            : "The book is published successfully!"
        );
        setSnackbarSeverity("success");

        // Update the book state locally if the API call succeeds
        setBooks((prevBooks) =>
          prevBooks.map((b) =>
            b._id === bookId ? { ...b, isPublish: !book.isPublish } : b
          )
        );
        setVisibleBooks((prevBooks) =>
          prevBooks.map((b) =>
            b._id === bookId ? { ...b, isPublish: !book.isPublish } : b
          )
        );
      }
    } catch (error) {
      console.error("Error toggling publish status:", error);
    }
  };

  return (
    <div className="ad-card-main-container rounded-5 p-4">
      <div className="ad-card-content-container d-flex  justify-content-between gap-4">
        <div
          className="ad-card-image-container d-flex align-items-center justify-content-center rounded-4"
          style={{ backgroundColor: backgroundColor || "#ffffff" }} // Apply background color
        >
          <img src={book.images[0] || imagePlaceHolder} alt="Book Cover" />
        </div>
        <div className="ad-section">
          <div className="ad-card-text-container  text-lg-start w-100 w-lg-75">
            <h3 className="ad-card-title m-0 fw-semibold fs-4 mb-3">
              {book.title}
            </h3>
            {city !== "Location not found" && (
              <div className="location-time-row d-flex  justify-content-between my-2 flex-column flex-lg-row row-gap-2">
                <p className="ad-card-location m-0 fs-6 fs-md-5">
                  <i
                    className="fa-solid fa-location-dot me-2"
                    style={{ color: "#000000" }}
                  />
                  {city}
                </p>
              </div>
            )}
          </div>
          <div className="ad-card-description-section fw-medium my-3  text-lg-start">
            <h4 className="mb-2">Description</h4>
            <p className="m-0 fw-light">{book.description}</p>
          </div>
          {/* <div className="ad-card-extra-details-section fw-medium my-5 d-none d-lg-block">
            <h4 className="m-0 text-center text-lg-start">Details</h4>
            <div className="extra-details d-flex justify-content-around mt-3">
              {detailsHeadings.map((heading, index) => (
                <div
                  className="extra-details-item text-center fs-6"
                  key={index}
                >
                  <div className="extra-detail-heading">
                    {heading} <span className="colon">:</span>
                  </div>
                  <div className="extra-detail-value">
                    {detailsValues[index]}
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div className="d-flex align-items-center justify-content-between  gap-3 mb-4">
            <span
              className="fs-5 ad"
              style={{
                color: book.isPublish ? "green" : "red",
                fontWeight: book.isPublish ? "600" : "400",
              }}
            >
              {book.isPublish ? "live" : "Not live"}
            </span>
            <Switch
              checked={book.isPublish}
              onChange={() => handleToggle(book._id)}
              color="primary"
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center  gap-3 ad-btn">
            <Button
              variant="primary"
              className="ad-card-remove-button fs-5 rounded-3 w-50 "
              onClick={() => handleRemove(book._id)} // Add your remove logic here
            >
              Remove
            </Button>
            <Button
              variant="primary"
              className="ad-card-edit-button fs-5 rounded-3 w-50"
              onClick={() => handleEdit(book._id)} // Add your edit logic here
            >
              Edit
            </Button>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default MyBookAdCard;
