import React, { useState } from "react";
import "./SellTutorServices.css";
import { Dropdown } from "primereact/dropdown";
import Button from "react-bootstrap/Button";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import { CreateServices } from "../../../Services/tutorServices/Services";
import PositionedSnackbar from "../../../Components/Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";

const SellTutorServices = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  // const [certificationFiles, setCertificationFiles] = useState([]);
  const [selectedTeachingMethod, setSelectedTeachingMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  // New state variables for each input field
  const [subject, setSubject] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [startTime, setStartTime] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [endTime, setEndTime] = useState("");
  const navigate = useNavigate();
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const languages = [
    { code: "ur", name: "Urdu" },
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "pa", name: "Punjabi" },
    { code: "sd", name: "Sindhi" },
    { code: "ps", name: "Pashto" },
    { code: "gk", name: "Gilgiti" },
  ];

  const handleSale = async () => {
    // Check for required fields
    if (
      !subject ||
      !qualification ||
      !experience ||
      !description ||
      !price ||
      !selectedTeachingMethod ||
      !selectedLanguage ||
      !startTime || // Ensure times are set
      !endTime ||
      !phoneNumber
    ) {
      setSnackbarMessage("Please fill all required fields!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return; // Exit the function if validation fails
    }
    setLoading(true);
    try {
      // Prepare the payload
      const payload = {
        subject: subject,
        fees: price,
        language: selectedLanguage.name, // Ensure this is a string
        teach_method: selectedTeachingMethod.name, // Ensure this is a string
        qualification: qualification,
        experience: `${experience} years`,
        description: description,
        phoneNumber: phoneNumber,
        // Assuming you want to use today's date for the start and end time
        availability: {
          from: "",
          to: "",
        },
      };

      // Get today's date in "YYYY-MM-DD" format
      const today = new Date().toISOString().split("T")[0];

      // Combine the date with the selected times to create ISO strings
      const formattedStartTime = new Date(
        `${today}T${startTime}:00Z`
      ).toISOString();
      const formattedEndTime = new Date(
        `${today}T${endTime}:00Z`
      ).toISOString();

      // Ensure 'availability' is set correctly
      if (formattedStartTime && formattedEndTime) {
        payload.availability.from = formattedStartTime;
        payload.availability.to = formattedEndTime;
      }

      // If you need to upload files, convert them to base64 or handle separately
      // Example: payload.certification = await convertFileToBase64(certificationFiles[0]);

      const res = await CreateServices(payload); // Send JSON payload

      if (res.status === 201) {
        setSnackbarMessage("Service created successfully!");
        setSnackbarOpen(true);
        setLoading(false);

        setTimeout(() => {
          navigate("/tutor-service-draft-page");
        }, 1000);
        // Reset form fields
        setSubject("");
        setQualification("");
        setExperience("");
        setDescription("");
        setPrice("");
        setSelectedTeachingMethod(null);
        setSelectedLanguage(null);
        setStartTime("");
        setEndTime("");
        // setCertificationFiles([]); // Clear certification files if needed
      } else if (res.status === 401) {
        setLoading(false);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      }
    } catch (error) {
      setLoading(false);

      console.error("Error creating service:", error);
      setSnackbarMessage("Failed to create service.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Convert to 24-hour format and then to minutes
  const convertTo24HourAndMinutes = (time) => {
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);

    // Adjust for AM/PM
    const isPM = time.toLowerCase().includes("pm");
    const isAM = time.toLowerCase().includes("am");

    if (isPM && hours !== 12) {
      hours += 12;
    } else if (isAM && hours === 12) {
      hours = 0; // Handle midnight case (12 AM)
    }

    return hours * 60 + parseInt(minutes, 10); // Return total minutes from midnight
  };

  // Handle end time change and validation
  const handleEndTimeChange = (e) => {
    const selectedEndTime = e.target.value;

    if (startTime && selectedEndTime) {
      const startMinutes = convertTo24HourAndMinutes(startTime);
      const endMinutes = convertTo24HourAndMinutes(selectedEndTime);

      // Ensure the end time is greater than the start time
      if (endMinutes <= startMinutes) {
        setSnackbarMessage("End time must be greater than start time.");
        setSnackbarOpen(true);
      } else {
        setEndTime(selectedEndTime);
      }
    }
  };
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 11 characters
    if (/^\d{0,11}$/.test(value)) {
      setPhoneNumber(value);
    }
  };
  return (
    <div>
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
      <div className="sell-tutor-services-form">
        <div className="header">
          <h5 className="fs-4 fw-bold">Tutor Service</h5>
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Subject:</label>
          <input
            type="text"
            className="w-100 p-0 m-0 fs-5"
            placeholder="Enter subject"
            value={subject} // Bind the state variable
            onChange={(e) => setSubject(e.target.value)} // Update state on change
          />
        </div>

        <div className="d-flex align-items-center justify-content-between column-gap-2 row-gap-3 my-3 flex-column flex-md-row">
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100">
            <label className="fs-5 p-0 m-0">Qualification:</label>
            <input
              type="text"
              className="w-100 p-0 m-0 fs-5"
              placeholder="Enter your qualification"
              value={qualification} // Bind the state variable
              onChange={(e) => setQualification(e.target.value)} // Update state on change
            />
          </div>
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100">
            <label className="fs-5 p-0 m-0">Experience:</label>
            <input
              type="number" // Change the input type to "number"
              className="w-100 p-0 m-0 fs-5"
              placeholder="Enter your experience in years"
              value={experience} // Bind the state variable
              onChange={(e) => setExperience(e.target.value)} // Update state on change
              min="0" // Optional: Prevent negative values
            />
          </div>
        </div>
        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Mode:</label>
          <div className="w-100">
            <Dropdown
              value={selectedTeachingMethod}
              onChange={(e) => setSelectedTeachingMethod(e.value)}
              options={[
                { name: "Onsite", code: "onsite" },
                { name: "Online", code: "online" },
                { name: "Both", code: "both" },
                { name: "Other", code: "other" },
              ]}
              optionLabel="name"
              showClear
              placeholder="Select Teaching Method"
              className="custom-dropdown d-flex align-items-center justify-content-between fs-5"
            />
          </div>
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100">
          <label className="fs-5 p-0 m-0">Language:</label>
          <div className="w-100">
            <Dropdown
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.value)}
              options={languages}
              optionLabel="name"
              showClear
              placeholder="Select Language"
              className="custom-dropdown d-flex align-items-center justify-content-between fs-5"
            />
          </div>
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="align-self-start fs-5 p-0 m-0">Description:</label>
          <textarea
            rows={5}
            className="w-100 p-0 m-0 fs-5"
            placeholder="Enter description"
            value={description} // Bind the state variable
            onChange={(e) => setDescription(e.target.value)} // Update state on change
          />
        </div>

        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Availability:</label>
          <div className="w-100 d-flex flex-column flex-md-row gap-2">
            <div className="field-container d-flex align-items-baseline justify-content-between flex-column flex-md-row column-gap-4 rounded-4 p-3 my-3">
              <label className="fs-6">From</label>
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)} // Use state to manage time
                className="fs-5"
              />
            </div>
            <div className="field-container d-flex align-items-baseline justify-content-between flex-column flex-md-row column-gap-4 rounded-4 p-3 my-3">
              <label className="fs-6">To</label>
              <input
                type="time"
                value={endTime}
                onChange={handleEndTimeChange}
                className="fs-5"
              />
            </div>
          </div>
        </div>
        <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
          <label htmlFor="phoneNumber" className="fs-5 p-0 m-0 w-max">
            Phone No:
          </label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder="Enter phone number"
            className="flex-grow p-0 m-0 fs-5"
          />
        </div>
        {/* 
        <div className="field-container gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Certifications:</label>
          <div className="d-flex align-items-center justify-content-start">
            <div className="certification-file-names mt-2">
              {certificationFiles.length > 0 && (
                <>
                  {certificationFiles.map((fileName, index) => (
                    <span key={index} className="fs-6">
                      {fileName}
                      {index < certificationFiles.length - 1 && ", "}
                    </span>
                  ))}
                </>
              )}
            </div>
          </div>
        </div> */}

        <div className="field-container gap-2 rounded-4 p-3 my-3">
          <label className="fs-5 p-0 m-0">Price:</label>
          <input
            type="number"
            className="w-100 p-0 m-0 fs-5"
            placeholder="Enter your price"
            value={price} // Bind the state variable
            onChange={(e) => setPrice(e.target.value)} // Update state on change
          />
        </div>

        <div className="d-flex align-items-center justify-content-center flex-column flex-md-row column-gap-5 row-gap-2 my-4">
          <Button
            variant="primary"
            className="overview-button fs-6 fs-md-5 rounded-4 py-3 w-md-25 w-100"
            onClick={handleModalOpen}
          >
            Overview
          </Button>

          <Button
            className="sale-now-button fs-6 fs-md-5 rounded-4 py-3 w-md-25 w-100"
            onClick={handleSale}
            disabled={loading}
          >
            {loading ? "Loading" : "Post Now"}
          </Button>
        </div>

        <CustomModal isOpen={modalOpen} onClose={handleModalClose}>
          <h5 className="modal-title fs-5">Overview</h5>
          <p>Subject: {subject || "N/A"}</p>
          <p>Qualification: {qualification || "N/A"}</p>
          <p>Experience: {experience || "N/A"}</p>

          <p>
            Selected Language:{" "}
            {selectedLanguage ? selectedLanguage.name : "None"}
          </p>
          <p>Method: {selectedTeachingMethod?.name || "N/A"}</p>

          {/* <p>
            Certifications:{" "}
            {certificationFiles.length > 0
              ? certificationFiles.join(", ")
              : "None"}
          </p> */}
          <p>
            Availability: {startTime ? startTime : "N/A"} -{" "}
            {endTime ? endTime : "N/A"}
          </p>
          <p>Price: {price ? price : "N/A"}</p>
          <p>Description: {description ? description : "N/A"}</p>
        </CustomModal>
      </div>
    </div>
  );
};

export default SellTutorServices;
