import React, { useState, useEffect } from "react";
import MyBookAdCard from "../../Components/MyBookAdCard/MyBookAdCard";
import Pagination from "../../Components/Pagination/Pagination";
import "./MyBookAds.css";
import { GetMyBook } from "../../Services/Book/Book";
import PositionedSnackbar from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";

export default function MyBookAds() {
  const [books, setBooks] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState({});
  const [visibleBooks, setVisibleBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const booksPerPage = 4;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  async function getRandomColor() {
    return new Promise((resolve) => {
      setTimeout(() => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        resolve(`rgb(${r}, ${g}, ${b}, 0.35)`);
      }, 500);
    });
  }

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const fetchedBooks = await GetMyBook();
      if (fetchedBooks.status === 200) {
        const onAdsBooks = fetchedBooks.data.data;
        const colorPromises = onAdsBooks.map(async (book) => {
          const color = await getRandomColor();
          return { id: book._id, color };
        });

        const colors = await Promise.all(colorPromises);
        const colorMap = {};
        colors.forEach(({ id, color }) => {
          colorMap[id] = color;
        });

        setBooks(onAdsBooks);
        setBackgroundColors(colorMap);
        setVisibleBooks(onAdsBooks.slice(0, booksPerPage));
        setLoading(false);
      } else if (fetchedBooks.status === 401) {
        setLoading(false);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        setVisibleBooks([]);
        setBooks([]);
        setLoading(false);
      }
    }
    fetchData();
  }, [navigate]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * booksPerPage;
    const newBooks = books.slice(startIndex, startIndex + booksPerPage);
    setVisibleBooks(newBooks);
  };

  const totalPages = Math.ceil(books.length / booksPerPage);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {visibleBooks.length === 0 ? (
            <div className="screen-size">No Book found</div>
          ) : (
            <div className="book-ads-container">
              <PositionedSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                handleClose={handleSnackbarClose}
              />
              <div className="book-ads-grid">
                {visibleBooks.map((book) => (
                  <MyBookAdCard
                    key={book._id}
                    book={book}
                    backgroundColor={backgroundColors[book._id]}
                    seller={book.seller}
                    buttonText="Buy Now" // Example button text
                    setBooks={setBooks}
                    setVisibleBooks={setVisibleBooks}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarMessage={setSnackbarMessage}
                    setSnackbarSeverity={setSnackbarSeverity}
                  />
                ))}
              </div>
              {totalPages > 1 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
