import React from "react";
import "./CustomModal.css"; // Ensure you style the modal appropriately

const CustomModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="custom-backdrop" onClick={onClose}>
      <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
        {/* X Close Icon */}
        <span className="modal-cross" onClick={onClose}>
          &times;
        </span>
        {children} {/* Render the passed children content */}
      </div>
    </div>
  );
};

export default CustomModal;
