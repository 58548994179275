import instance from "../instant";

const GetOneBook = async (id) => {
    try {
        const response = await instance.get(`book/by_id/${id}`);
        return response;
    } catch (error) {
        return error;
    }
};
const GetAllBook = async () => {
    try {
        const response = await instance.get(`book`);
        return response;
    } catch (error) {
        return error;
    }
};
const GetBooksByCategory = async (id) => {
    try {
        const response = await instance.get(`book/get-by-categoryId?cat_id=${id}`);
        return response;
    } catch (error) {
        return error;
    }
};
const GetBooksByLocation = async (lat,lng) => {
    try {
        const response = await instance.get(`book/search-by-location?latitude=${lat}&longitude=${lng}`);
        return response;
    } catch (error) {
        return error;
    }
};
const GetBooksBySearch = async (title) => {
    try {
        const response = await instance.get(`book/search?title=${title}`);
        return response;
    } catch (error) {
        return error;
    }
};
const CreateBook = async (formData) => {
    try {
      const headers = {};
      const token = JSON.parse(localStorage.getItem("token"));
      if (token !== null) {
        headers["Authorization"] = `Bearer ${token}`;
      }
      const response = await instance.post(`book/create`, formData, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
};


const GetMyBook = async () => {
  try {
    const headers = {};
    const token = JSON.parse(localStorage.getItem("token"));

    if (token !== null) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await instance.get(`book/my`, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching books:", error);
    return error;
  }
};
  
const UpdateBook = async (id,data) => {
    try {
      const headers = {}; 
      const token = JSON.parse(localStorage.getItem("token"));
  
      if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const response = await instance.patch(`book/update/${id}`, data,{
        headers,
      });
      
      return response;
    } catch (error) {
      console.error("Error fetching books:", error);
      return error;
    }
};

  
const DeleteBook = async (id) => {
    try {
      const headers = {}; 
      const token = JSON.parse(localStorage.getItem("token"));
  
      if (token !== null) {
        headers['Authorization'] = `Bearer ${token}`;
      }
  
      const response = await instance.delete(`book/delete/${id}`,{
        headers,
      });
      
      return response;
    } catch (error) {
      console.error("Error fetching books:", error);
      return error;
    }
};




  
export { GetOneBook,GetAllBook,GetBooksByCategory,GetBooksByLocation,GetBooksBySearch,CreateBook,GetMyBook,UpdateBook,DeleteBook};