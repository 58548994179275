import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.css";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Popper from "@mui/material/Popper";
import ProfileOptions from "../ProfileOptions/ProfileOptions";
import { UseUser } from "../../Context/UserContext";

const NavBar = () => {
  // State management for Library Popper
  const [libraryAnchorEl, setLibraryAnchorEl] = useState(null);
  const [libraryOpen, setLibraryOpen] = useState(false);

  // State management for Add Popper
  const [addAnchorEl, setAddAnchorEl] = useState(null);
  const [addOpen, setAddOpen] = useState(false);

  // State management for Profile Popper 1 (for mobile)
  const [profileAnchorEl1, setProfileAnchorEl1] = useState(null);
  const [profileOpen1, setProfileOpen1] = useState(false);

  // State management for Profile Popper 2 (for desktop)
  const [profileAnchorEl2, setProfileAnchorEl2] = useState(null);
  const [profileOpen2, setProfileOpen2] = useState(false);

  const [showNav, setShowNav] = useState(false);
  const location = useLocation();
  const { user, setSelectedCategory } = UseUser();
  const [showNavbar, setShowNavbar] = useState(false);

  // Function to toggle the navbar
  const handleToggle = () => {
    setShowNavbar(!showNavbar);
  };

  // Function to close the navbar when a link is clicked
  const handleLinkClick = () => {
    setShowNavbar(false);
  };
  // Handling Popper Open/Close
  const handleLibraryClick = (event) => {
    setLibraryAnchorEl(event.currentTarget);
    setLibraryOpen((prev) => !prev);
    setSelectedCategory(null);

  };

  const handleAddClick = (event) => {
    setAddAnchorEl(event.currentTarget);
    setAddOpen((prev) => !prev);
  };

  const handleProfileClick1 = (event) => {
    setProfileAnchorEl1(event.currentTarget);
    setProfileOpen1((prev) => !prev);
  };

  const handleProfileClick2 = (event) => {
    setProfileAnchorEl2(event.currentTarget);
    setProfileOpen2((prev) => !prev);
  };

  const handleClickAway = (setter) => () => setter(false);

  useEffect(() => {
    const isSignUpCreateAccountPage =
      location.pathname === "/sign-up-with" ||
      location.pathname === "/create-new-account" ||
      location.pathname === "/login" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/forgot-password";
    setShowNav(!isSignUpCreateAccountPage);
  }, [location.pathname]);

  const getActiveClass = (paths) =>
    paths.includes(location.pathname) ? "active-link" : "";
  const handleCloseNavbar = () => {
    setShowNavbar(false);
  };

  return (
    <>
      {" "}
      <div className="the-navbar">
        {showNav ? (
          <ClickAwayListener onClickAway={handleCloseNavbar}>
            <Navbar
              expand="lg"
              className="py-3"
              style={{ margin: "8px 6vw" }}
              expanded={showNavbar}
            >
              {/* <Container> */}
              <Navbar.Brand
                href="/"
                className="d-flex align-items-center justify-content-between p-0 m-0 text-decoration-none"
              >
                <img
                  className="navbar-logo"
                  src={require("../../Assets/books-brary-logo.png")}
                  alt="Books Brary"
                />
                <h3 className="logo-text m-0 p-0 pt-1 ps-2 fs-4">
                  Books Brary
                </h3>
              </Navbar.Brand>
              <div className="d-flex align-items-center gap-2 gap-sm-2">
                {user ? (
                  <div className="fs-5 d-lg-none d-block">
                    <ClickAwayListener
                      onClickAway={handleClickAway(setProfileOpen1)}
                    >
                      <div>
                        <div className="d-inline-block">
                          <div
                            className="profile-button"
                            // style={{background: "white", boxShadow: "none"}}
                            onClick={handleProfileClick1}
                            style={{
                              padding: " 1px 10px",
                            }}
                          >
                            <i
                              className="fa-regular fa-user fa-xs"
                              // style={{color: "#0084F0"}}
                            ></i>
                          </div>
                        </div>
                        <Popper
                          onClick={handleClickAway(setProfileOpen1)}
                          open={profileOpen1}
                          anchorEl={profileAnchorEl1}
                          placement="bottom-start"
                          transition
                          disablePortal
                          modifiers={[
                            { name: "offset", options: { offset: [0, 8] } },
                          ]}
                          style={{ zIndex: "1300" }}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper
                                elevation={10}
                                className="popper-content p-4 rounded-4"
                                style={{ width: "320px" }}
                              >
                                <ProfileOptions closeOption={setProfileOpen1} />
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </div>
                    </ClickAwayListener>
                  </div>
                ) : (
                  <></>
                )}
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  onClick={handleToggle}
                />
              </div>

              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="nav-items nav-items-collapse ms-auto gap-1 gap-md-3">
                  <Link
                    to="/"
                    className={`d-flex align-items-center underline-none nav-links fs-5 ${getActiveClass(
                      ["/"]
                    )}`}
                    onClick={handleLinkClick}
                  >
                    Home
                  </Link>
                  {/* Library Nav Link with Popper */}
                  <Link
                    className={`d-flex align-items-center nav-links fs-5 ${getActiveClass(
                      ["/selling-books-library", "/demanding-books-library"]
                    )}`}
                  >
                    <ClickAwayListener
                      onClickAway={handleClickAway(setLibraryOpen)}
                    >
                      <div>
                        <div className="d-inline-block">
                          <div
                            className="library-button-container d-flex align-items-center justify-content-between rounded-5"
                            onClick={handleLibraryClick}
                          >
                            <p className="m-0">Library</p>
                          </div>
                        </div>
                        <Popper
                          onClick={handleClickAway(setLibraryOpen)}
                          open={libraryOpen}
                          anchorEl={libraryAnchorEl}
                          placement="bottom-start"
                          transition
                          disablePortal
                          modifiers={[
                            { name: "offset", options: { offset: [0, 8] } },
                          ]}
                          style={{ zIndex: "1300" }}
                        >
                          {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                              <Paper
                                elevation={10}
                                className="popper-content p-4 rounded-4"
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  className={`${getActiveClass([
                                    "/selling-books-library",
                                  ])}`}
                                  to="/selling-books-library"
                                  onClick={handleLinkClick}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="p-0 m-0">Selling Books</h5>
                                    <i
                                      className="fa-solid fa-chevron-right"
                                      style={{ color: "currentColor" }}
                                    ></i>
                                  </div>
                                </Link>
                                <hr />
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  to="/demanding-books-library"
                                  className={`${getActiveClass([
                                    "/demanding-books-library",
                                  ])}`}
                                  onClick={handleLinkClick}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="p-0 m-0">Demanding Books</h5>
                                    <i
                                      className="fa-solid fa-chevron-right"
                                      style={{ color: "#b5b5b5" }}
                                    ></i>
                                  </div>
                                </Link>
                              </Paper>
                            </Fade>
                          )}
                        </Popper>
                      </div>
                    </ClickAwayListener>
                  </Link>
                  <Link
                    to="/tutor-library"
                    className={`d-flex align-items-center nav-links fs-5 ${getActiveClass(
                      ["/tutor-library"]
                    )}`}
                    onClick={handleLinkClick}
                  >
                    Tutor
                  </Link>

                  {user ? (
                    <>
                      <div className="fs-5 p-0">
                        <ClickAwayListener
                          onClickAway={handleClickAway(setAddOpen)}
                        >
                          <div>
                            <div className="d-inline-block">
                              <div
                                className="add-button-container d-flex align-items-center justify-content-between rounded-5 p-1"
                                onClick={handleAddClick}
                              >
                                <i
                                  className="fa-solid fa-plus "
                                  style={{
                                    color: "#ffffff",
                                    padding: "8px 9px",
                                  }}
                                ></i>
                                <p className="p-0 px-md-2 px-1 m-0 fw-light">
                                  Add
                                </p>
                              </div>
                            </div>
                            <Popper
                              onClick={handleClickAway(setAddOpen)}
                              open={addOpen}
                              anchorEl={addAnchorEl}
                              placement="bottom-start"
                              transition
                              disablePortal
                              modifiers={[
                                { name: "offset", options: { offset: [0, 8] } },
                              ]}
                              style={{ zIndex: "1300" }}
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper
                                    elevation={10}
                                    className="popper-content p-4 rounded-4"
                                  >
                                    <div>
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        to="/sell-book-form"
                                        onClick={handleLinkClick}
                                        className={`d-flex align-items-center justify-content-between ${getActiveClass(
                                          ["/sell-book-form"]
                                        )}`}
                                      >
                                        <h5 className="p-0 m-0">Sell A Book</h5>
                                        <i
                                          className="fa-solid fa-chevron-right"
                                          style={{ color: "currentColor" }}
                                        ></i>
                                      </Link>
                                    </div>
                                    <hr />
                                    <div>
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        to="/demand-book-form"
                                        onClick={handleLinkClick}
                                        className={`d-flex align-items-center justify-content-between ${getActiveClass(
                                          ["/demand-book-form"]
                                        )}`}
                                      >
                                        <h5 className="p-0 m-0">
                                          Demanding Book
                                        </h5>
                                        <i
                                          className="fa-solid fa-chevron-right"
                                          style={{ color: "currentColor" }}
                                        ></i>
                                      </Link>
                                    </div>
                                    <hr />
                                    <div>
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        onClick={handleLinkClick}
                                        to="/sell-tutor-services-form"
                                        className={`d-flex align-items-center justify-content-between ${getActiveClass(
                                          ["/sell-tutor-services-form"]
                                        )}`}
                                      >
                                        <h5 className="p-0 m-0">Tutor</h5>
                                        <i
                                          className="fa-solid fa-chevron-right"
                                          style={{ color: "currentColor" }}
                                        ></i>
                                      </Link>
                                    </div>
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        </ClickAwayListener>
                      </div>
                      <div className="fs-5 d-none d-lg-block p-0">
                        <ClickAwayListener
                          onClickAway={handleClickAway(setProfileOpen2)}
                        >
                          <div>
                            <div className="d-inline-block">
                              <div
                                className="profile-button"
                                onClick={handleProfileClick2}
                              >
                                <i className="fa-regular fa-user"></i>
                              </div>
                            </div>
                            <Popper
                              onClick={handleClickAway(setProfileOpen2)}
                              open={profileOpen2}
                              anchorEl={profileAnchorEl2}
                              placement="bottom-start"
                              transition
                              disablePortal
                              modifiers={[
                                { name: "offset", options: { offset: [0, 8] } },
                              ]}
                              style={{ zIndex: "1300" }}
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Paper
                                    elevation={10}
                                    className="profile-options popper-content p-4 rounded-4"
                                  >
                                    <ProfileOptions
                                      closeOption={setProfileOpen2}
                                    />{" "}
                                  </Paper>
                                </Fade>
                              )}
                            </Popper>
                          </div>
                        </ClickAwayListener>
                      </div>
                    </>
                  ) : (
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      onClick={handleLinkClick}
                      to="/sign-up-with"
                    >
                      <Button
                        variant="outline-primary"
                        className="nav-button rounded-5 p-0"
                      >
                        <i className="fa-regular fa-user fa-lg me-2 p-0"></i>
                        Login
                      </Button>
                    </Link>
                  )}
                </Nav>
              </Navbar.Collapse>
              {/* </Container> */}
            </Navbar>
          </ClickAwayListener>
        ) : null}
      </div>
    </>
  );
};
export default NavBar;
