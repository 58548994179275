import React from "react";
import Rating from "@mui/material/Rating";
import Button from "react-bootstrap/Button";
import "./TutorCard.css";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../libs/FormateTime";
import { calculateAverageRating } from "../../libs/calculateAvgerageRating";
const TutorCard = ({ tutor }) => {
  const navigate = useNavigate();
  const buttonText = "Chat";
  const tutorId = tutor._id;

  const handleViewDetails = () => {
    navigate("/tutor-details", { state: { tutorId, buttonText } });
  };

  const fromTime = formatDate(tutor.availability.from);
  const toTime = formatDate(tutor.availability.to);

  const averageRating = calculateAverageRating(tutor);
  function formatPhoneNumber(number) {
    // Check if the number starts with a '+' (indicating it already has a country code)
    if (!number.startsWith("+")) {
      // Add the default country code if it's missing
      const defaultCountryCode = "+92"; // Change this if needed
      number = defaultCountryCode + number.replace(/^0+/, ""); // Remove any leading zeroes
    }
    return number;
  }

  const handleButtonClick = () => {
    const whatsappUrl = `https://wa.me/${formatPhoneNumber(tutor.phoneNumber)}`;
    // Open the URL in a new tab
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div className="tutor-card   d-flex align-items-center justify-content-around flex-column gap-3 m-3">
      <div className="tutor-info p-3 d-flex align-items-center justify-content-between   flex-column gap-8 w-full h-100">
        {tutor && tutor.user_id && tutor.user_id.profileImage ? (
          <img
            src={tutor.user_id.profileImage}
            alt={`${tutor.user_id.name || "Tutor"}'s Pic`}
            className="tutor-image"
          />
        ) : (
          <div className="tutorcard-image-before d-flex align-items-center justify-content-center">
            <i
              className="fa-solid fa-user"
              style={{ color: "white", fontSize: "80px" }}
            ></i>
          </div>
        )}

        <div className="d-flex flex-column p-0 w-100 gap-2 flex-grow ">
          <h5 className="first-row d-flex align-items-center justify-content-start w-full tutor-name fw-bold fs-5 m-0 p-0">
            {tutor && tutor.user_id && tutor.user_id.name}
          </h5>
          <div className="second-row tutor-subject d-flex align-items-center justify-content-between w-100">
            <p className="m-0 p-0 mobile-text-small">{tutor.subject}</p>
            <p className="m-0 p-0 tutor-price rounded-4 px-2 py-1 mobile-text-small">
              Rs.{tutor.fees}
            </p>
          </div>
          <div className="fourth-row d-flex align-items-center justify-content-between w-100">
            <p className="m-0 p-0 mobile-text-small">{tutor.experience}</p>

            <Rating
              size="small"
              name="half-rating-read"
              value={averageRating}
              precision={0.5}
              readOnly
            />
          </div>

          <div className="third-row tutor-subject d-flex w-100">
            {/* Display formatted from and to times */}
            <p className="m-0 p-0 mobile-text-small my-1">
              {fromTime} - {toTime}
            </p>
          </div>

          <div className="d-flex fs-6 w-100 gap-2">
            {tutor.phoneNumber && (
              <Button
                variant="primary"
                onClick={handleButtonClick}
                className="hire-now-button d-flex align-items-center justify-content-center w-100 py-2 fs-6"
                style={{ borderRadius: "13.4px" }}
              >
                <i className="fa-regular fa-comment-dots me-2" />
                {buttonText}
              </Button>
            )}
            <Button
              variant="primary"
              onClick={handleViewDetails}
              className="hire-now-button d-flex align-items-center justify-content-center w-100 py-2 fs-6"
              style={{ borderRadius: "13.4px" }}
            >
              Info
            </Button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorCard;
