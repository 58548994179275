import React, { useEffect, useState } from "react";
import "./SellBookForm.css";
import { Dropdown } from "primereact/dropdown";
import Button from "react-bootstrap/Button";
import AddImages from "../../../Components/AddImages/AddImages";
import CustomModal from "../../../Components/CustomModal/CustomModal"; // Import the modal component
import { GetCategory } from "../../../Services/Category/Category";
import PositionedSnackbar from "../../../Components/Snackbar/Snackbar";

import Modal from "react-bootstrap/Modal";
import Map from "../../../Components/Map";
import { CreateBook } from "../../../Services/Book/Book";
import { useNavigate } from "react-router-dom";
const SellBookForm = () => {
  const [selectedCondition, setSelectedCondition] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [isDemand, setIsDemand] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // New state variables for additional fields
  const [bookTitle, setBookTitle] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookISBN, setBookISBN] = useState("");
  const [bookEdition, setBookEdition] = useState("");
  const [bookPublisher, setBookPublisher] = useState("");
  const [bookPages, setBookPages] = useState("");
  const [bookFormat, setBookFormat] = useState("");
  const [stockQuantity, setStockQuantity] = useState("");
  const [discount, setDiscount] = useState("");
  const [saleStatus, setSaleStatus] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [shippingDetail, setShippingDetail] = useState("");
  const [tags, setTags] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState(""); // State for book description
  const [inputValue, setInputValue] = useState(""); // State for current input value
  const [showOptionalFields, setShowOptionalFields] = useState(false);
  const [isExchangeable, setIsExchangeable] = useState(false);

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  // const [isPublish, setIsPublish] = useState(false);
  const navigate = useNavigate();
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [locationCoordinates, setLocationCoordinates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState("");
  useEffect(() => {
    async function GetCategories() {
      const fetchCategory = await GetCategory();
      if (fetchCategory.status === 200) {
        const categories = fetchCategory.data.data;
        setCategory(categories);
      }
    }

    GetCategories();
  }, []);
  const currentURL = window.location.href;

  useEffect(() => {
    // Check if the URL contains "demand-book-form"
    if (currentURL.includes("demand-book-form")) {
      setIsDemand(true); // Set state to true if URL contains the string
    } else {
      setIsDemand(false); // Otherwise, set state to false
    }
  }, [currentURL]);

  const handleConditionClick = (condition) => {
    setSelectedCondition(condition === selectedCondition ? null : condition);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const languages = [
    { code: "ur", name: "Urdu" },
    { code: "en", name: "English" },
    { code: "ar", name: "Arabic" },
    { code: "pa", name: "Punjabi" },
    { code: "sd", name: "Sindhi" },
    { code: "ps", name: "Pashto" },
    { code: "gk", name: "Gilgiti" },
  ];

  const handleLocationModalOpen = () => {
    setLocationModalOpen(true);
  };

  const handleLocationModalClose = () => {
    setLocationModalOpen(false);
  };

  // Fetch coordinates using Google Maps Geocoding API
  const fetchCoordinates = async (locationSearch) => {
    setLocation(locationSearch);
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${locationSearch}&key=AIzaSyAmyoc4UgruCA5Xlf8YCB3JpEqBBEFVGw0`
      );
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        setLocationCoordinates(location);
      } else {
        alert("Location not found. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching location data: ", error);
    }
  };

  const conditionMap = {
    NEW: "New",
    Good: "Used - Good",
    ACCEPTABLE: "Used - Acceptable",
    "LIKE NEW": "Used - Like New", // Assuming "LIKE NEW" maps to "Used - Like New"
  };
  const conditions = Object.keys(conditionMap);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleCheckboxChange = (e) => {
    setIsExchangeable(e.target.checked);
  };
  const handleSale = async () => {
    if (
      !selectedCategory ||
      !bookTitle ||
      !price ||
      !selectedCondition ||
      !phoneNumber
    ) {
      setSnackbarMessage("Please fill all required fields!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    setLoading(true);
    try {
      const formdata = new FormData();
      const formattedCondition = conditionMap[selectedCondition];
      formdata.append("cat_id", selectedCategory._id);
      formdata.append("isOnDemand", String(isDemand));
      formdata.append("title", bookTitle);
      formdata.append("price", String(price));
      formdata.append("condition", formattedCondition);
      formdata.append("isPublish", isDemand ? String(true) : String(false));
      previews.forEach((file, index) => {
        if (file) formdata.append(`images`, file, file.name);
      });
      formdata.append(
        "isExchangeable",
        isDemand ? String(false) : isExchangeable
      );
      if (bookAuthor) formdata.append("author", bookAuthor);
      if (selectedLanguage?.name)
        formdata.append("language", selectedLanguage.name);
      if (bookISBN) formdata.append("ISBN", bookISBN);
      if (bookEdition) formdata.append("edition", bookEdition);
      if (bookPublisher) formdata.append("publisher", bookPublisher);
      if (bookPages) formdata.append("pages", String(bookPages));
      if (bookFormat) formdata.append("formate", bookFormat);
      if (stockQuantity)
        formdata.append("stockQuantity", String(stockQuantity));
      if (discount) formdata.append("discount", String(discount));
      if (saleStatus) formdata.append("saleStatus", saleStatus);
      if (description) formdata.append("description", description);
      if (phoneNumber) formdata.append("phoneNuber", phoneNumber);
      if (locationCoordinates) {
        formdata.append(
          "location",
          JSON.stringify({
            type: "Point",
            coordinates: [locationCoordinates.lat, locationCoordinates.lng],
          })
        );
      }
      if (shippingDetail) formdata.append("shippingDetail", shippingDetail);
      if (tags?.length) formdata.append("tags", JSON.stringify(tags));
      const res = await CreateBook(formdata);
      if (res.status === 200) {
        setSnackbarMessage("Service created successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setLoading(false);

        setTimeout(() => {
          // if (isDemand) {
          //   navigate("/my-book-demand");
          // } else {
          //   navigate("/my-book-ads");
          // }
          navigate("/my-book-ads");
        }, 1000);
        resetForm();
      } else if (res.status === 401) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        setLoading(false);

        navigate("/login");
      }
    } catch (error) {
      console.error(
        "Error creating BOOK:",
        error.response ? error.response.data : error.message
      );
      setLoading(false);

      setSnackbarMessage("Failed to create BOOK.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const resetForm = () => {
    setSelectedCategory(null);
    setBookAuthor("");
    setBookTitle("");
    setSelectedLanguage(null);
    setDescription("");
    setBookISBN("");
    setPrice("");
    setSelectedCondition(null);
    setBookEdition("");
    setBookPublisher("");
    setBookPages("");
    setBookFormat("");
    setStockQuantity("");
    setSaleStatus("");
    setPhoneNumber("");
    setLocationCoordinates(null);
    setShippingDetail("");
    setTags([]);
    setPreviews([]);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value); // Update input value
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault(); // Prevent form submission

      setTags((prevTags) => [...prevTags, inputValue.trim()]); // Add tag to the array
      setInputValue(""); // Clear the input
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove)); // Remove tag from the array
  };
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    // Allow only digits and limit to 11 characters
    if (/^\d{0,11}$/.test(value)) {
      setPhoneNumber(value);
    }
  };
  return (
    <div className="sell-book-form">
      <PositionedSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        handleClose={handleSnackbarClose}
      />
      <div className="header">
        <h5 className="fs-4 fw-bold">
          {isDemand ? "Demanding Book" : "Sell Book"}
        </h5>
      </div>
      <AddImages previews={previews} setPreviews={setPreviews} />

      {/* Title Field */}
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookTitle" className="fs-5 p-0 m-0 w-max">
              Title:
            </label>
            <input
              type="text"
              id="bookTitle"
              value={bookTitle}
              onChange={(e) => setBookTitle(e.target.value)}
              placeholder="Enter book title"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          {/* Author Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookAuthor" className="fs-5 p-0 m-0 w-max">
              Author:
            </label>
            <input
              type="text"
              id="bookAuthor"
              value={bookAuthor}
              onChange={(e) => setBookAuthor(e.target.value)}
              placeholder="Enter author name"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {/* Phone Number Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="phoneNumber" className="fs-5 p-0 m-0 w-max">
              Phone No:
            </label>
            <input
              type="text"
              id="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              placeholder="Enter phone number"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          {/* Publisher Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookPublisher" className="fs-5 p-0 m-0 w-max">
              Publisher:
            </label>
            <input
              type="text"
              id="bookPublisher"
              value={bookPublisher}
              onChange={(e) => setBookPublisher(e.target.value)}
              placeholder="Enter publisher"
              className="flex-grow p-0 m-0 fs-5"
            />
          </div>
        </div>
      </div>
      {/* Condition Selection */}
      <div className="field-container rounded-4 py-3 px-3 m-0 my-4 my-lg-0">
        <div className="d-flex flex-wrap gap-2">
          <label className="fs-5 p-0 m-0 flex-grow-0 d-flex align-items-center w-max">
            Condition:
          </label>
          {conditions.map((condition) => (
            <button
              key={condition}
              className={`condition-button text-center rounded-5 py-md-2 px-md-4 px-3 py-2 flex-grow-0 ${
                condition === selectedCondition ? "selected-book-condition" : ""
              }`}
              onClick={() => handleConditionClick(condition)}
            >
              {condition}
            </button>
          ))}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {/* Price Field */}
          <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="bookPrice" className="fs-5 p-0 m-0 w-max">
              Price:
            </label>
            <input
              type="number"
              id="bookPrice"
              placeholder="Enter price"
              className="flex-grow p-0 m-0 fs-5"
              value={price} // Bind state value here
              onChange={(e) => setPrice(e.target.value)} // Update state on change
            />
          </div>
        </div>
        <div className="col-12 col-md-6">
          {/* Category Selection */}
          <div className="field-container align-items-center rounded-4 py-2 px-3 m-0 my-3">
            <div className="d-flex flex-wrap gap-4 w-full align-items-center no-wrap-on-large px-1  my-1">
              <label htmlFor="category" className="fs-5 p-0 m-0 w-max">
                Category:
              </label>
              <Dropdown
                id="category"
                value={selectedCategory} // This should hold the full category object
                options={category} // Assuming 'categories' is your array of category objects
                onChange={(e) => {
                  setSelectedCategory(e.value); // Store the selected category object
                }}
                placeholder="Select a category"
                className="flex-grow"
                optionLabel="title" // Use 'title' to display in the dropdown
              />
            </div>
          </div>
        </div>
      </div>
      {!isDemand && (
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="checkbox"
            class="checkbox"
            checked={isExchangeable}
            onChange={handleCheckboxChange}
          />
          <label for="checkbox" class="checkbox-label">
            Exchangeable
          </label>
        </div>
      )}
      {/* Description Field */}
      <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
        <label
          htmlFor="bookDescription"
          className="fs-5 p-0 m-0 align-self-start w-max"
        >
          Description:
        </label>
        <textarea
          rows={5}
          id="bookDescription"
          placeholder="Enter book description"
          className="flex-grow p-0 m-0 fs-5"
          value={description} // Bind state value here
          onChange={(e) => setDescription(e.target.value)} // Update state on change
        />
      </div>
      <div className="d-flex justify-content-center my-4">
        <Button
          variant="link"
          onClick={() => setShowOptionalFields(!showOptionalFields)}
          className="fs-6 fs-md-5 rounded-4 py-3 text-gray"
          style={{ color: "gray", textDecoration: "none" }}
        >
          {showOptionalFields
            ? "Hide Additional Details"
            : "Additional Details (Optional)"}
        </Button>
      </div>

      {showOptionalFields && (
        <>
          {/* Discount Field */}
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="discount" className="fs-5 p-0 m-0 w-max">
                  Discount:
                </label>
                <input
                  type="number"
                  id="discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  placeholder="Enter discount percentage"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* ISBN Field */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="bookISBN" className="fs-5 p-0 m-0 w-max">
                  ISBN:
                </label>
                <input
                  type="text"
                  id="bookISBN"
                  value={bookISBN}
                  onChange={(e) => setBookISBN(e.target.value)}
                  placeholder="Enter ISBN"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              {/* Languages Selection */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 w-100 my-3">
                <label className="fs-5 p-0 m-0">Language:</label>
                <div className="w-100">
                  <Dropdown
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e.value)}
                    options={languages}
                    optionLabel="name"
                    showClear
                    placeholder="Select Language"
                    className="custom-dropdown d-flex align-items-center justify-content-between fs-5"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* Edition Field */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="bookEdition" className="fs-5 p-0 m-0 w-max">
                  Edition:
                </label>
                <input
                  type="text"
                  id="bookEdition"
                  value={bookEdition}
                  onChange={(e) => setBookEdition(e.target.value)}
                  placeholder="Enter edition"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              {/* Pages Field */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="bookPages" className="fs-5 p-0 m-0 w-max">
                  Pages:
                </label>
                <input
                  type="number"
                  id="bookPages"
                  value={bookPages}
                  onChange={(e) => setBookPages(e.target.value)}
                  placeholder="Enter number of pages"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* Format Field */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="bookFormat" className="fs-5 p-0 m-0 w-max">
                  Format:
                </label>
                <input
                  type="text"
                  id="bookFormat"
                  value={bookFormat}
                  onChange={(e) => setBookFormat(e.target.value)}
                  placeholder="Enter format (e.g., Paperback, Hardcover)"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              {/* Stock Quantity Field */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="stockQuantity" className="fs-5 p-0 m-0 w-max">
                  {isDemand ? "Required Quantity:" : "Stock Quantity:"}
                </label>
                <input
                  type="number"
                  id="stockQuantity"
                  value={stockQuantity}
                  onChange={(e) => setStockQuantity(e.target.value)}
                  placeholder="Enter stock quantity"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* Sale Status Field */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="saleStatus" className="fs-5 p-0 m-0 w-max">
                  Sell Status:
                </label>
                <input
                  type="text"
                  id="saleStatus"
                  value={saleStatus}
                  onChange={(e) => setSaleStatus(e.target.value)}
                  placeholder="Enter sell status"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              {/* Shipping Detail Field */}
              <div className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
                <label htmlFor="shippingDetail" className="fs-5 p-0 m-0 w-max">
                  Shipping Detail:
                </label>
                <input
                  type="text"
                  id="shippingDetail"
                  value={shippingDetail}
                  onChange={(e) => setShippingDetail(e.target.value)}
                  placeholder="Enter shipping detail"
                  className="flex-grow p-0 m-0 fs-5"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              {/* Add a button to open the location modal */}
              {!locationCoordinates && (
                <div
                  className="field-container d-flex align-items-start align-items-md-center justify-content-between flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3"
                  onClick={handleLocationModalOpen}
                >
                  <label className="fs-5 p-0 m-0 w-max">Add Location</label>
                  <Button
                    variant="transparent"
                    className="flex-grow p-0 m-0 fs-5 outline-none w-100 loc-btn"
                  >
                    <div className="d-flex align-items-start align-items-md-center justify-content-between w-100 gap-2">
                      <span style={{ color: "#a9a3af" }}>
                        Add Your Location
                      </span>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20px"
                          height="20px"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
                            fill="#009AF0"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.08296 7C2.50448 4.48749 4.48749 2.50448 7 2.08296V0H9V2.08296C11.5125 2.50448 13.4955 4.48749 13.917 7H16V9H13.917C13.4955 11.5125 11.5125 13.4955 9 13.917V16H7V13.917C4.48749 13.4955 2.50448 11.5125 2.08296 9H0V7H2.08296ZM4 8C4 5.79086 5.79086 4 8 4C10.2091 4 12 5.79086 12 8C12 10.2091 10.2091 12 8 12C5.79086 12 4 10.2091 4 8Z"
                            fill="#009AF0"
                          />
                        </svg>
                      </span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
          {/* Location Modal */}
          <Modal show={locationModalOpen} onHide={handleLocationModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Select Location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Map
                fetchCoordinates={fetchCoordinates}
                handleLocationModalClose={handleLocationModalClose}
              />
            </Modal.Body>
          </Modal>

          {/* Display selected location */}
          {location !== "" && (
            <div className="selected-location">
              <p>Selected Location: {location}</p>
            </div>
          )}

          {/* Tags Field */}
          <div className="field-container d-flex align-items-start align-items-md-center  flex-column flex-md-row column-gap-2 rounded-4 p-3 my-3">
            <label htmlFor="tags" className="fs-5 p-0 m-0 w-max">
              Tags:
            </label>
            <div className="d-flex flex-wrap flex-grow">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className="tag-container d-flex align-items-center me-2 mb-2 p-1 rounded-lg"
                  style={{ backgroundColor: "#c5c5c5c5" }}
                >
                  <span className="tag">{tag}</span>
                  <button
                    type="button"
                    className="btn-close ms-2"
                    onClick={() => removeTag(tag)}
                    aria-label="Remove tag"
                  ></button>
                </div>
              ))}
              <input
                type="text"
                id="tags"
                value={inputValue} // Bind input value
                onChange={handleInputChange} // Update input value on change
                onKeyPress={handleKeyPress} // Handle Enter key press
                placeholder="Enter tags and hit enter"
                className="flex-grow p-0 m-0 fs-5"
              />
            </div>
          </div>
        </>
      )}
      <Button
        className="sale-now-button fs-6 fs-md-5 rounded-4 py-3 w-md-25 w-100"
        onClick={handleSale}
        disabled={loading}
      >
        {loading ? "Loading" : "Sell Book"}
      </Button>

      {/* Custom Modal for confirmation */}
      <CustomModal open={modalOpen} onClose={handleModalClose}>
        <h5>Confirm your details:</h5>
        {/* Display the details here */}
        <div>
          <p>Title: {bookTitle}</p>
          <p>Author: {bookAuthor}</p>
          <p>ISBN: {bookISBN}</p>
          <p>Edition: {bookEdition}</p>
          <p>Publisher: {bookPublisher}</p>
          <p>Pages: {bookPages}</p>
          <p>Format: {bookFormat}</p>
          <p>Stock Quantity: {stockQuantity}</p>
          <p>Discount: {discount}</p>
          <p>Sell Status: {saleStatus}</p>
          <p>Phone Number: {phoneNumber}</p>
          <p>Shipping Detail: {shippingDetail}</p>
          <p>Tags: {tags}</p>
        </div>
        <Button
          className="bg-success text-white"
          onClick={() => {
            handleModalClose();
          }}
        >
          Confirm
        </Button>
      </CustomModal>
    </div>
  );
};

export default SellBookForm;
